const blue = {
  10: "#E8F4FF",
  15: "#C7E4FF",
  24: "#76B6F1",
  41: "#178CF8",
  50: "#0C77D9",
  55: "#036CD4",
  60: "#055DA8",
};

const navy = {
  10: "#EFF4FD",
  24: "#C2D2ED",
  55: "#4A6FA2",
  68: "#2E4F7F",
  78: "#223255",
};

const green = {
  10: "#ECFBEE",
  20: "#A9E6BC",
  41: "#269C71",
  55: "#047C65",
  60: "#09695F",
};

const orange = {
  10: "#FEF6E0",
  20: "#FEC95E",
  28: "#F9A600",
  50: "#AC6213",
};

const red = {
  13: "#FFECEF",
  24: "#FCBABD",
  41: "#E35A5B",
  50: "#D14447",
  55: "#C6393D",
  60: "#AD2D39",
};

const grey = {
  0: "#FFFFFF",
  10: "#FAFAFB",
  12: "#F7F8FA",
  18: "#E4E4E9",
  28: "#BABAC0",
  40: "#888B92",
  55: "#6C6C72",
  75: "#3A3F48",
  100: "#212427",
};

const metricsColors = [
  "#E73978",
  "#0D4B5F",
  "#F4D15F",
  "#844CD2",
  "#C87717",
  "#00229C",
  "#A42115",
  "#E87EE3",
  "#98AC19",
  "#006E9D",
];

const palette = {
  blue,
  green,
  grey,
  metricsColors,
  navy,
  orange,
  red,
};

export type Palette = typeof palette;

export { blue, green, grey, metricsColors, navy, orange, red };
