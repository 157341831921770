import type { ErrorResponseBody, IUserState } from "@pillpal/api-types";
import { useQuery } from "@tanstack/react-query";
import { currentUserAPI } from "apis/auth";
import type { AxiosError } from "axios";
import { hocify } from "helpers/hoc";
import { useToast } from "infra/toasts";
import React from "react";
import { create } from "zustand";

export type WithAuthorizationType = {
  authState: {
    user?: IUserState;
    isLoading: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    isError: boolean;
    hasAccess: boolean;
    showTimeout: boolean;
    requestAuth: () => void;
  };
};

export const AuthorizationQueryKey = "currentUser";

type TimeOutState = {
  showTimeout: boolean;
  setTimeout: () => void;
  clearTimeout: () => void;
};

export const useTimeoutStore = create<TimeOutState>((set) => ({
  showTimeout: false,
  setTimeout: () => set(() => ({ showTimeout: true })),
  clearTimeout: () => set(() => ({ showTimeout: false })),
}));

// API to fetch logged in user data
export const useAuthorizedUser = (enabled = true) => {
  const toast = useToast();
  const showTimeout = useTimeoutStore((state) => state.showTimeout);
  const query = useQuery<IUserState, AxiosError<ErrorResponseBody>>({
    queryKey: [AuthorizationQueryKey],
    queryFn: async () => currentUserAPI(),
    onError: (error: AxiosError<ErrorResponseBody>) => {
      toast.error(error.message || "Failed to retrieve user");
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 3600000,
    cacheTime: 3600000,
    enabled,
  });

  const isLoaded = (query.isError || query.isSuccess) && !query.isLoading;

  return React.useMemo(
    () => ({
      user: query.data,
      isLoading: query.isLoading,
      isSuccess: query.isSuccess,
      isFetching: query.isFetching,
      isError: query.isError,
      hasAccess: !!query.data && isLoaded,
      showTimeout,
      requestAuth: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        query.refetch();
      },
    }),
    [isLoaded, query, showTimeout]
  );
};

export const withAuthorization = hocify(useAuthorizedUser, "authState");
