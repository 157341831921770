"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodingSystems = exports.CodeType = exports.bloodPressureCodes = void 0;
exports.bloodPressureCodes = {
    BLOOD_PRESSURE_ROOT_CODE: "85354-9",
    SYSTOLIC: "8480-6",
    DIASTOLIC: "8462-4",
};
var CodeType;
(function (CodeType) {
    CodeType["ANSWER"] = "answer";
    CodeType["QUESTION"] = "question";
    CodeType["SCORE"] = "score";
    CodeType["ROOT"] = "root";
})(CodeType = exports.CodeType || (exports.CodeType = {}));
var CodingSystems;
(function (CodingSystems) {
    CodingSystems["LOINC"] = "http://loinc.org";
    CodingSystems["MEMORA"] = "MEMORA";
    CodingSystems["ICD10"] = "http://hl7.org/fhir/sid/icd-10-cm";
    CodingSystems["SNOMED"] = "http://snomed.info/sct";
    CodingSystems["ICD9"] = "http://hl7.org/fhir/sid/icd-9-cm/diagnosis";
    CodingSystems["HL7ACR"] = "http://terminology.hl7.org/CodeSystem/appointment-cancellation-reason";
    CodingSystems["HL7SC"] = "http://terminology.hl7.org/CodeSystem/service-category";
    CodingSystems["HL7ST"] = "http://terminology.hl7.org/CodeSystem/service-type";
})(CodingSystems = exports.CodingSystems || (exports.CodingSystems = {}));
