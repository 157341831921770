"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTwilioMessageErrorMessage = exports.TwilioStatusCodes = exports.activeSurveyErrorMessage = exports.DiscussionFilter = exports.DeliveryStatus = exports.DataDirection = exports.onlyImagesMessage = void 0;
exports.onlyImagesMessage = "<image only>";
var DataDirection;
(function (DataDirection) {
    DataDirection["OUTGOING"] = "outgoing";
    DataDirection["INCOMING"] = "incoming";
})(DataDirection = exports.DataDirection || (exports.DataDirection = {}));
var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["SENDING"] = "Sending";
    DeliveryStatus["SENT"] = "Sent";
    DeliveryStatus["FAILED"] = "Failed";
    DeliveryStatus["UNDELIVERED"] = "Undelivered";
    DeliveryStatus["NOT_DELIVERED"] = "Not delivered";
    DeliveryStatus["DELIVERED"] = "Delivered";
    DeliveryStatus["NO_ANSWER"] = "no-answer";
    DeliveryStatus["IN_PROGRESS"] = "in-progress";
    DeliveryStatus["COMPLETED"] = "completed";
    DeliveryStatus["UNSUCCESSFUL"] = "unsuccessful";
    DeliveryStatus["ACCEPTED"] = "Accepted";
    DeliveryStatus["QUEUED"] = "Queued";
})(DeliveryStatus = exports.DeliveryStatus || (exports.DeliveryStatus = {}));
var DiscussionFilter;
(function (DiscussionFilter) {
    DiscussionFilter["USER_TAGGED"] = "userTagged";
    DiscussionFilter["ALL"] = "all";
})(DiscussionFilter = exports.DiscussionFilter || (exports.DiscussionFilter = {}));
exports.activeSurveyErrorMessage = "There is an active survey that will be closed for this patient";
// Status codes: www.twilio.com/docs/api/errors#3-anchor
exports.TwilioStatusCodes = {
    UNKNOWN_DESTINATION: 30005,
    CONNECTION_ERROR: 31005,
    UNREACHABLE_DESTINATION: 30003,
    UNREGISTERED_NUMBER: 30034,
    UNSUBSCRIBED_RECIPIENT: 21610,
    INVALID_PHONE_NUMBER: 21614,
    INVALID_FORMAT: 21211,
    MESSAGING_SERVICE_INVALID: 21703,
    UNREACHABLE_CARRIER: 30006,
};
const TwilioMessageErrorMessage = {
    [exports.TwilioStatusCodes.UNKNOWN_DESTINATION]: "The phone number you are trying to reach is unknown and may no longer exist.",
    [exports.TwilioStatusCodes.CONNECTION_ERROR]: "Error sending message due to connection issues.",
    [exports.TwilioStatusCodes.UNREACHABLE_DESTINATION]: "The phone number you are trying to reach is switched off or otherwise unavailable.",
    [exports.TwilioStatusCodes.UNREGISTERED_NUMBER]: "The phone number you are trying to reach is not registered.",
    [exports.TwilioStatusCodes.UNSUBSCRIBED_RECIPIENT]: "The person you are trying to reach has opted out of receiving messages from your phone number.",
    [exports.TwilioStatusCodes.INVALID_PHONE_NUMBER]: "The phone number is invalid.",
    [exports.TwilioStatusCodes.INVALID_FORMAT]: "The phone number is invalid.",
    [exports.TwilioStatusCodes.MESSAGING_SERVICE_INVALID]: "Phone number in your Messaging Service is not capable of sending message to this recipient.",
    [exports.TwilioStatusCodes.UNREACHABLE_CARRIER]: "The destination number is either landline or unable to receive the message",
};
const getTwilioMessageErrorMessage = (code) => TwilioMessageErrorMessage[code] || "Error sending message to the recipient";
exports.getTwilioMessageErrorMessage = getTwilioMessageErrorMessage;
