/* eslint-disable max-classes-per-file */
import { getLogger } from "loglevel";

import LoglevelRemote from "./logLevelRemote";
import SentryPlugin from "./logLevelSentry";

const clientLogger = getLogger("main-logger");

// setting up sentry transport plugin
const sentryPlugin = new SentryPlugin();

// setting log remote api plugin
const logRemotePlugin = new LoglevelRemote();

const flushClientLogs = () => {
  logRemotePlugin.flush();
};

// installing plugins to logger
sentryPlugin.install(clientLogger);
logRemotePlugin.install(clientLogger);

// enabling all levels to log
clientLogger.enableAll();

export { clientLogger, flushClientLogs };
