import type { SeverityLevel } from "@sentry/react";
import { addBreadcrumb, captureException } from "@sentry/react";
import type { Logger, LogLevelNames, LogLevelNumbers } from "loglevel";

import { USE_SENTRY } from "../infra/config";

/**
 * Maps Loglevel error levels to sentry error levels
 */
const LOGLEVEL_TO_SENTRY: Record<LogLevelNames, SeverityLevel> = {
  trace: "debug",
  info: "info",
  debug: "debug",
  warn: "warning",
  error: "error",
};

export default class LoglevelSentry {
  install(logger: Logger): void {
    const defaultMethodFactory = logger.methodFactory;

    // eslint-disable-next-line no-param-reassign
    logger.methodFactory = (
      method: LogLevelNames,
      level: LogLevelNumbers,
      name: string | symbol
    ) => {
      const defaultMethod = defaultMethodFactory(method, level, name);

      return (...args: any) => {
        if (defaultMethod) defaultMethod(...args);

        if (!USE_SENTRY) {
          return;
        }

        // getting data, message from arguments array
        const [message, data] = args;

        // exclude cases where the boundary catches, so we don't double log
        if (data?.caughtInBoundary) {
          return;
        }

        // always capture everything to a breadcrumb
        const sentryLevel =
          method in LOGLEVEL_TO_SENTRY ? LOGLEVEL_TO_SENTRY[method] : undefined;
        addBreadcrumb({
          message,
          level: sentryLevel,
          data: {
            ...data,
            level: method,
          },
        });

        // Please use only err while passing errors to logger. This check is a fallback catch
        const errorObject = data?.err || data?.error;

        // log errors as their own thing
        if (method === "warn" || method === "error") {
          // message may be an error
          if (errorObject && errorObject instanceof Error) {
            captureException(errorObject);
            return;
          }

          // okay so we can't find an error, at least maybe we log the message
          // or at least something so we can look at the breadcrumbs
          const errorMessage = typeof message === "string" ? message : "Error";
          captureException(new Error(errorMessage));
        }
      };
    };
    logger.setLevel(logger.getLevel());
  }
}
