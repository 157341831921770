import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./auth.styles.css";

import { StrictMode } from "react";
import ReactDOM from "react-dom";

import { EnvDesignations } from "../components/EnvDesignations";
import { AuthPage } from "../containers/login/AuthPage";
import { RootThemeProvider } from "../infra/theming/RootThemeProvider";

const App = () => (
  <RootThemeProvider>
    <EnvDesignations position="top" />
    <AuthPage />
  </RootThemeProvider>
);

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.querySelector("#root")
);
