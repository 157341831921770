"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.make = exports.DateConstants = void 0;
exports.DateConstants = {
    MINUTES_TO_MILLIS: 60000,
    TIMEZONE_IDS: {
        LA: "America/Los_Angeles",
    },
};
function make(obj) {
    return obj;
}
exports.make = make;
