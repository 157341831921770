"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IfValueType = exports.ValueIfOperationWithoutComparison = exports.ValueIfOperationWithComparison = void 0;
var ValueIfOperationWithComparison;
(function (ValueIfOperationWithComparison) {
    ValueIfOperationWithComparison["EQUALS"] = "equals";
    ValueIfOperationWithComparison["LESS_THAN"] = "lessThan";
    ValueIfOperationWithComparison["LESS_THAN_OR_EQUAL_TO"] = "lessThanOrEqualTo";
    ValueIfOperationWithComparison["GREATER_THAN"] = "greaterThan";
    ValueIfOperationWithComparison["GREATER_THAN_OR_EQUAL_TO"] = "greaterThanOrEqualTo";
})(ValueIfOperationWithComparison = exports.ValueIfOperationWithComparison || (exports.ValueIfOperationWithComparison = {}));
var ValueIfOperationWithoutComparison;
(function (ValueIfOperationWithoutComparison) {
    ValueIfOperationWithoutComparison["TRUTHY"] = "truthy";
    ValueIfOperationWithoutComparison["FALSY"] = "falsy";
    ValueIfOperationWithoutComparison["HAS_PASSED"] = "hasPassed";
    ValueIfOperationWithoutComparison["HASNT_PASSED"] = "hasntPassed";
})(ValueIfOperationWithoutComparison = exports.ValueIfOperationWithoutComparison || (exports.ValueIfOperationWithoutComparison = {}));
var IfValueType;
(function (IfValueType) {
    IfValueType["LITERAL"] = "literal";
    IfValueType["REFERENCE"] = "reference";
})(IfValueType = exports.IfValueType || (exports.IfValueType = {}));
