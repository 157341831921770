const ENVIRONMENT_LOOKUP = Object.freeze({
  development: "dev" as const,
  dev: "dev" as const,
  undefined: "dev" as const, // If nothing is specified, use dev.
  prod2: "prod2" as const,
  prod: "prod2" as const,
  test: "test" as const,
  sandbox: "sandbox" as const,
  prodca: "prod2ca" as const,
  prod2ca: "prod2ca" as const,
  demo: "demo" as const,
  qa1: "qa1" as const,
  qa2: "qa2" as const,
  qa3: "qa3" as const,
  qa4: "qa4" as const,
  qa5: "qa5" as const,
  qa6: "qa6" as const,
});

const WEB_DOMAIN = {
  dev: `http://localhost:${process.env.PILLPAL_PORT || 3000}`,
  demo: "https://demo.memorahealth.com",
  test: "https://test.memorahealth.com",
  sandbox: "https://sandbox.memorahealth.com",
  prod2: "https://secure.memorahealth.com",
  prod2ca: "https://ca.secure.memorahealth.com",
  qa1: "https://qa1.memorahealth.com",
  qa2: "https://qa2.memorahealth.com",
  qa3: "https://qa3.memorahealth.com",
  qa4: "https://qa4.memorahealth.com",
  qa5: "https://qa5.memorahealth.com",
  qa6: "https://qa6.memorahealth.com",
};

const ENV =
  ENVIRONMENT_LOOKUP[
    (process.env.REACT_APP_SENTRY_ENV as keyof typeof ENVIRONMENT_LOOKUP) ||
      "dev"
  ];

export const apiBaseUrl = `${WEB_DOMAIN[ENV as keyof typeof WEB_DOMAIN]}`;
