// Inspired from https://raw.githubusercontent.com/torusresearch/loglevel-sentry/
import type { Logger, LogLevelNames, LogLevelNumbers } from "loglevel";

import { clientLogsAPI } from "../apis/logApi";
import type { SendFn } from "./batchHelper";
import { BatchHelper } from "./batchHelper";

// Maps logLevel method to winston before raising it on backend
// Only trace method is called verbose in winston.
function returnWinstonLevel(level: string) {
  switch (level) {
    case "trace":
      return "verbose";
    default:
      return level;
  }
}
export default class LoglevelRemote {
  private batchLogger: BatchHelper;

  // insert methods for which you want to immediately log errors to backend
  private flushList: LogLevelNames[] = ["error", "trace"];

  constructor() {
    this.batchLogger = new BatchHelper(this._send, {
      batchSize: 25,
      intervalMs: 5000,
    });
  }

  public flush() {
    this.batchLogger.flush();
  }

  private _send: SendFn = async (entryArray: any) => {
    try {
      await clientLogsAPI(entryArray);
    } catch (err) {
      /* empty */
    }
  };

  install(logger: Logger): void {
    const defaultMethodFactory = logger.methodFactory;

    // eslint-disable-next-line no-param-reassign
    logger.methodFactory = (
      method: LogLevelNames,
      level: LogLevelNumbers,
      name: string | symbol
    ) => {
      const defaultMethod = defaultMethodFactory(method, level, name);
      return (...args: any) => {
        const [message, data] = args;
        // batch logging all errors
        this.batchLogger.log(
          {
            message,
            level: returnWinstonLevel(method),
            ...data,
          },
          { flush: this.flushList.includes(method) }
        );
        if (defaultMethod) defaultMethod(...args);
      };
    };

    // setting logger back to origial level and passing control back to log level
    logger.setLevel(logger.getLevel());
  }
}
