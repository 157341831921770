import { axiosClient } from "../helpers/axiosClient";

export interface ActivityBeacon {
  patientId?: string;
  path: string;
  date: Date;
  validMs: number;
}

/** Given a marker for client activity, log it to the backend. */
export const activityBeaconAPI = (data: ActivityBeacon) =>
  axiosClient.post("/client-beacon", data);

export type LogEntry = {
  level: string;
  message: string;
  [optionName: string]: any;
};
export type LogsArray = Array<LogEntry>;

/** Given an array of log events, log them to the backend. */
export const clientLogsAPI = (data: LogsArray) => {
  const body = JSON.stringify(data, errorSerializer);
  const sentViaBeacon = navigator.sendBeacon(`/client-logs`, body);
  if (!sentViaBeacon) {
    return axiosClient.post(`/client-logs`, data);
  }
  return undefined;
};
function errorSerializer(key: string, value: any) {
  if (value instanceof Error) {
    return {
      name: value.name,
      message: value.message,
    };
  }
  return value;
}
