import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { resetCachedActionItemQueryParams } from "../actionItems/helpers";
import MemoraLogo from "../app/assets/memora-logo.svg";
import { ActivateAccount } from "./ActivateAccount";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import { Totp } from "./Totp";
import { decodeBase64, getUrlParams } from "./util";

const modeTitles: { [key in Mode]: string } = {
  login: "Sign in",
  totp: "Authenticator code",
  "forgot-password": "Forgot password",
  "reset-password": "Reset password",
  "activate-account": "Activate account",
  "resend-activation": "Resend Activation Link",
};

type Mode =
  | "login"
  | "totp"
  | "forgot-password"
  | "reset-password"
  | "activate-account"
  | "resend-activation";

function locationToMode(location: Location): Mode {
  const pathname = location.pathname.toLowerCase();
  if (pathname === "/reset-password") {
    return "reset-password";
  }
  if (pathname === "/activate-account") {
    return "activate-account";
  }
  if (pathname === "/resend-activation") {
    return "resend-activation";
  }
  return "login";
}

export const AuthPage: React.FC = () => {
  const [mode, setMode] = React.useState<Mode>(locationToMode(window.location));
  const queryParams = React.useRef(getUrlParams(window.location));
  const redirect = queryParams.current.ref || queryParams.current.redirect;
  const initialMessage = queryParams.current.msg
    ? decodeBase64(queryParams.current.msg)
    : "";

  // fields shared across login and TOTP pages
  const [username, setUsername] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();

  const onForgotPassword = () => {
    setPassword(undefined);
    setMode("forgot-password");
  };

  const onLogin = () => {
    setPassword(undefined);
    setMode("login");
  };

  const onTotp = () => {
    setMode("totp");
  };

  /**
   * Remove the prevously cached state on session timeout and re-logins.
   */
  React.useEffect(() => {
    // Since the constants cannot be accessed by this file using string literals here instead of helper.
    resetCachedActionItemQueryParams();
  }, []);

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Grid container className={styles.formContainer} direction="column">
        <Grid item>
          <img src={MemoraLogo} className={styles.logo} alt="Memora Health" />
        </Grid>
        <Grid item>
          <Typography variant="h2" component="h2" className={styles.title}>
            {modeTitles[mode]}
          </Typography>
        </Grid>
        <Grid item className={styles.item}>
          {mode === "login" && (
            <Login
              redirect={redirect}
              initialMessage={initialMessage}
              onForgotPassword={onForgotPassword}
              onTotp={onTotp}
              onChangeUsername={setUsername}
              onChangePassword={setPassword}
            />
          )}
          {mode === "totp" && (
            <Totp
              redirect={redirect}
              username={username!}
              password={password!}
            />
          )}
          {mode === "forgot-password" && <ForgotPassword onLogin={onLogin} />}
          {mode === "reset-password" && <ResetPassword />}
          {mode === "activate-account" && <ActivateAccount />}
          {mode === "resend-activation" && (
            <ForgotPassword onLogin={onLogin} isActivationLink />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background:
      "linear-gradient(135deg, rgba(21,77,142,1) 0%, rgba(75,138,211,1) 42%, rgba(255,255,255,1) 100%)",
    // height needs overrides as follows:
    // IE11 does not support `fit-content`, so it specifies 100%
    // mobile Safari has URL bar space, so it uses -webkit-fill-available to use less space
    height: "100%",
    "@supports (height: fit-content)": {
      height: "fit-content",
    },
    minHeight: "100%",
    "@supports (min-height: -webkit-fill-available)": {
      minHeight: "-webkit-fill-available",
    },
  },
  item: {
    width: "100%",
  },
  title: {
    marginTop: 8,
  },
  logo: {
    width: 256,
    maxHeight: 45,
  },
  formContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: 500,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "rgba(256, 256, 256, 0.85)",
    position: "relative",
    borderRadius: 2,
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12)",
    minHeight: "380px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      boxShadow: "unset",
    },
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 24,
  },
}));
