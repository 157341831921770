"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAPIErrors = void 0;
var UserAPIErrors;
(function (UserAPIErrors) {
    UserAPIErrors["INVALID_DOMAIN"] = "Failed to create account, email has an invalid domain.";
    UserAPIErrors["INVALID_EMAIL"] = "Failed to create an account, invalid email address format.";
    UserAPIErrors["ACCOUNT_ALREADY_EXISTS"] = "That account already exists.";
    UserAPIErrors["ACCOUNT_ALREADY_EXISTS_ON_SUBPROVIDER"] = "That account already exists on this subprovider.";
})(UserAPIErrors = exports.UserAPIErrors || (exports.UserAPIErrors = {}));
