import "fonts/suisse/suisse.css";

import { createTheme } from "@material-ui/core/styles";
import * as newMemoraPalette from "components/system/theme/palette";
import { typography as newThemeTypography } from "components/system/theme/theme";

// so as not to blow away MUI grey
const { grey, ...restOfPalette } = newMemoraPalette;

const palette = {
  primary: {
    main: restOfPalette.blue[55],
    contrastText: grey[0],
  },
  secondary: {
    main: restOfPalette.navy[68],
  },
  success: {
    main: restOfPalette.green[55],
  },
  error: {
    main: restOfPalette.red[50],
  },
  ...restOfPalette,
  memoraGrey: grey,
};

export const legacyTheme = createTheme({
  spacing: 4,
  palette,
  typography: newThemeTypography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1125,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        backgroundColor: palette.secondary.main,
        color: palette.primary.contrastText,
        "& button.MuiIconButton-root": {
          color: "white",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "initial",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1.2rem",
      },
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          color: palette.primary.main,
          "&:hover": {
            backgroundColor: "rgba(43, 122, 120, 0.08)",
          },
        },
      },
      colorPrimary: {
        "&$checked": {
          color: palette.primary.main,
          "&:hover": {
            backgroundColor: "rgba(43, 122, 120, 0.08)",
          },
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: "rgba(43, 122, 120, 0.08)",
        },
      },
    },
  },
});
