"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conditionHasComparison = exports.operationHasComparison = exports.isGenericHookConfig = void 0;
const types_1 = require("./types");
function isGenericHookConfig(hookConfig) {
    var _a;
    return !!((_a = hookConfig === null || hookConfig === void 0 ? void 0 : hookConfig.functionParameters) === null || _a === void 0 ? void 0 : _a.where);
}
exports.isGenericHookConfig = isGenericHookConfig;
function operationHasComparison(operation) {
    return !Object.values(types_1.ValueIfOperationWithoutComparison).includes(operation);
}
exports.operationHasComparison = operationHasComparison;
function conditionHasComparison(condition) {
    return operationHasComparison(condition.operation);
}
exports.conditionHasComparison = conditionHasComparison;
