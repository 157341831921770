"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tipProgramCustomHookConfigPropsSchema = exports.customFunctionParametersSchema = exports.tipProgramGenericHookConfigPropsSchema = exports.metadataSchema = exports.genericFunctionParametersSchema = exports.baseFunctionParametersSchema = exports.compoundIfConditionSchema = exports.ifConditionWithoutComparisonSchema = exports.ifConditionWithComparisonSchema = exports.ifValueReferenceSchema = exports.ifValueLiteralSchema = void 0;
const Yup = __importStar(require("yup"));
const time_1 = require("../time");
const types_1 = require("./types");
const utils_1 = require("./utils");
exports.ifValueLiteralSchema = Yup.object({
    type: Yup.mixed()
        .test("is-literal", `Value is not ${types_1.IfValueType.LITERAL}`, (value) => value === types_1.IfValueType.LITERAL)
        .required(),
    value: Yup.mixed().required(),
});
exports.ifValueReferenceSchema = Yup.object({
    type: Yup.mixed()
        .test("is-reference", `Value is not ${types_1.IfValueType.REFERENCE}`, (value) => value === types_1.IfValueType.REFERENCE)
        .required(),
    value: Yup.string().strict().required(),
});
// This is a duplicate declaration in containers/admin/ProgramBuilder/ProgramRules/validationUtils.ts,
// and cannot be exported since typescript is not allowing
const ifValueRequiredSchema = Yup.lazy((ifValue) => {
    if ((ifValue === null || ifValue === void 0 ? void 0 : ifValue.type) === types_1.IfValueType.LITERAL) {
        return exports.ifValueLiteralSchema;
    }
    return exports.ifValueReferenceSchema;
});
exports.ifConditionWithComparisonSchema = Yup.object({
    leftValue: ifValueRequiredSchema,
    operation: Yup.mixed()
        .oneOf(Object.values(types_1.ValueIfOperationWithComparison))
        .required(),
    rightValue: ifValueRequiredSchema,
});
exports.ifConditionWithoutComparisonSchema = Yup.object({
    leftValue: ifValueRequiredSchema,
    operation: Yup.mixed()
        .oneOf(Object.values(types_1.ValueIfOperationWithoutComparison))
        .required(),
});
const ifConditionComparisonSchema = Yup.lazy((condition) => {
    if ((0, utils_1.operationHasComparison)(condition.operation)) {
        return exports.ifConditionWithComparisonSchema;
    }
    return exports.ifConditionWithoutComparisonSchema;
});
exports.compoundIfConditionSchema = Yup.object().shape({
    conditions: Yup.array().of(ifConditionComparisonSchema).default([]),
});
const DayOfWeekValues = Object.values(time_1.DayOfWeek);
const AnchorDateModifiersOptionsSchema = Yup.object({
    dayOfWeek: Yup.string().required().oneOf(DayOfWeekValues),
    includeAnchorDate: Yup.boolean().required(),
    weekOffset: Yup.number().required(),
});
exports.baseFunctionParametersSchema = Yup.object().shape({
    anchorDatePath: Yup.string().strict().required(),
    tipProgramName: Yup.string().strict().required(),
    anchorDateModifierOptions: Yup.lazy((value) => value ? AnchorDateModifiersOptionsSchema : Yup.mixed().notRequired()),
});
exports.genericFunctionParametersSchema = exports.baseFunctionParametersSchema.shape({
    where: exports.compoundIfConditionSchema.required(),
});
exports.metadataSchema = Yup.object({
    displayName: Yup.string().trim().required(),
    description: Yup.string().trim().required(),
});
exports.tipProgramGenericHookConfigPropsSchema = exports.metadataSchema.shape({
    programId: Yup.string().strict().required(),
    functionParameters: exports.genericFunctionParametersSchema.required(),
});
exports.customFunctionParametersSchema = exports.baseFunctionParametersSchema.shape({
    customHookName: Yup.string().strict().required(),
});
exports.tipProgramCustomHookConfigPropsSchema = Yup.object({
    programId: Yup.string().strict().required(),
    functionParameters: exports.customFunctionParametersSchema.required(),
});
