"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayOfWeek = void 0;
var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["SUNDAY"] = "sunday";
    DayOfWeek["MONDAY"] = "monday";
    DayOfWeek["TUESDAY"] = "tuesday";
    DayOfWeek["WEDNESDAY"] = "wednesday";
    DayOfWeek["THURSDAY"] = "thursday";
    DayOfWeek["FRIDAY"] = "friday";
    DayOfWeek["SATURDAY"] = "saturday";
})(DayOfWeek = exports.DayOfWeek || (exports.DayOfWeek = {}));
