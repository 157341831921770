"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidFitbitForwardLink = exports.isValidFitbitConsentResponse = void 0;
function isValidFitbitConsentResponse(request) {
    return (!!request.code &&
        !!request.state);
}
exports.isValidFitbitConsentResponse = isValidFitbitConsentResponse;
function isValidFitbitForwardLink(request) {
    return !!request.fitbitLinkId;
}
exports.isValidFitbitForwardLink = isValidFitbitForwardLink;
