"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleName = void 0;
var RoleName;
(function (RoleName) {
    RoleName["ANYONE"] = "anyone";
    RoleName["PHYSICIAN"] = "physician";
    RoleName["ADMIN"] = "admin";
    RoleName["SUPERADMIN"] = "superadmin";
})(RoleName = exports.RoleName || (exports.RoleName = {}));
