"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listOfInbasketFields = exports.InbasketTypes = void 0;
// currently, we only support epic inbaskets
var InbasketTypes;
(function (InbasketTypes) {
    InbasketTypes["EPIC"] = "Epic";
    InbasketTypes["Cerner"] = "Cerner";
})(InbasketTypes = exports.InbasketTypes || (exports.InbasketTypes = {}));
exports.listOfInbasketFields = Object.values(InbasketTypes).map((value) => ({
    label: value,
    value: value,
}));
