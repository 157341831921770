"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentSender = exports.ContentPriority = void 0;
/**
 * Priority of an individual piece of Content
 */
var ContentPriority;
(function (ContentPriority) {
    ContentPriority["HIGH"] = "HIGH";
    ContentPriority["NORMAL"] = "NORMAL";
})(ContentPriority = exports.ContentPriority || (exports.ContentPriority = {}));
/**
 * Which entity sent this piece of Content
 */
var ContentSender;
(function (ContentSender) {
    ContentSender["CTU"] = "CTU";
    ContentSender["SYSTEM_MANUAL"] = "SYSTEM_MANUAL";
    ContentSender["SYSTEM_FELIX"] = "SYSTEM_FELIX";
    ContentSender["SYSTEM_AUTOMATED"] = "SYSTEM_AUTOMATED";
})(ContentSender = exports.ContentSender || (exports.ContentSender = {}));
