export class Observable<TEventData> {
  observers = new Set<(data: TEventData) => void>();

  subscribe(fn: (data: TEventData) => void) {
    this.observers.add(fn);
  }

  unsubscribe(fn: (data: TEventData) => void) {
    this.observers.delete(fn);
  }

  notify(data: TEventData) {
    this.observers.forEach((o) => o(data));
  }
}
