/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { loginApi } from "apis/auth";
import * as React from "react";

import { useStyles } from "./sectionStyles";

const strings = {
  noAppLink: "Unable to use authenticator app",
  noAppText:
    "If you are unable to access your authenticator app, contact your Memora Health administrator to unenroll your account from multi-factor authentication.",
  description: "Enter the six-digit code from your authenticator app",
  error: "Something went wrong",
};

interface ITotpProps {
  redirect: string | undefined;
  username: string;
  password: string;
}

const nonNumericRegex = /\D/g;

export const Totp = (props: ITotpProps) => {
  const { username, password, redirect } = props;

  const onSubmit = React.useCallback(
    (code: string) => {
      setErrorMessage(undefined);
      setIsSubmitting(true);
      loginApi(username, password, redirect, code)
        .then((res) => {
          setIsSubmitting(false);
          if (res.status === 200 && res.data.redirect) {
            window.location.href = res.data.redirect;
          } else {
            setErrorMessage(res?.data?.message || strings.error);
          }
          return undefined;
        })
        .catch((error) => {
          setIsSubmitting(false);
          setErrorMessage(error?.response?.data?.message || strings.error);
        });
    },
    [password, username, redirect]
  );

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [totp, setTotp] = React.useState<string>("");

  const onChange = React.useCallback(
    (str: string) => {
      if (isSubmitting) {
        return;
      }
      const newTotp = str.replace(nonNumericRegex, "").slice(0, 6);
      if (totp !== newTotp) {
        setTotp(newTotp);
        if (newTotp.length === 6) {
          onSubmit(newTotp);
        }
      }
    },
    [isSubmitting, totp, onSubmit]
  );

  const onNoApp = () => {
    // eslint-disable-next-line no-alert
    window.alert(strings.noAppText);
  };

  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <Typography component="span">{strings.description}</Typography>
      <Typography component="span" className={styles.error}>
        {errorMessage}
      </Typography>
      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item className={styles.inputItem}>
          <TextField
            variant="outlined"
            label=""
            size="small"
            inputProps={{
              maxLength: 6,
              size: 6,
              className: styles.totpInput,
            }}
            autoComplete="one-time-code"
            placeholder="XXXXXX"
            onChange={(val) => onChange(val.target.value)}
            value={totp}
            required
          />
        </Grid>
      </Grid>
      <div className={styles.progressBarContainer}>
        {isSubmitting && <LinearProgress className={styles.progressBar} />}
      </div>
      <Link component="button" onClick={onNoApp}>
        {strings.noAppLink}
      </Link>
    </Container>
  );
};
