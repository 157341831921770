"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPJVMedicationDoseEvent = exports.isPamTipEvent = exports.SurveyActionType = void 0;
var SurveyActionType;
(function (SurveyActionType) {
    SurveyActionType["TIMEOUT_SURVEY"] = "timeout-survey";
    SurveyActionType["SEND_SURVEY"] = "send-survey";
    SurveyActionType["REPROMPT_SURVEY"] = "reprompt-survey";
    SurveyActionType["RESCHEDULE_SURVEYS_FOR_DND"] = "reschedule-surveys-for-dnd";
})(SurveyActionType = exports.SurveyActionType || (exports.SurveyActionType = {}));
function isPamTipEvent(event) {
    return event.type === "tip";
}
exports.isPamTipEvent = isPamTipEvent;
function isPJVMedicationDoseEvent(event) {
    return event.type === "medication-dose";
}
exports.isPJVMedicationDoseEvent = isPJVMedicationDoseEvent;
