/* eslint-disable max-classes-per-file */
export class AuthMissingError extends Error {
  constructor() {
    super("Login again");
    this.name = "AuthMissingError";
  }
}

export class FetchError extends Error {
  public response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
    this.name = "FetchError";
  }
}
