/* eslint-disable react/display-name */

/**
 * Converts hooks to higher order components for use case with class components.
 * 'propName' is required as the values returned by the hook maybe a single value, array or object.
 * 'propName' also defines the name used to access the values provided by the hook in class components.
 *
 * @param useReactHook
 * @param propName The name of the prop which is passed to the higher order component.
 * @param options Optional arguments which can be passed to the hook.
 * @returns Higher Order Component for class components.
 */
export const hocify =
  (useReactHook: any, propName: string, options?: any) =>
  (WrappedComponent: any) =>
  (props: any) => {
    const result = options ? useReactHook(options) : useReactHook();

    const newProps = {
      [propName]: result,
    };

    return <WrappedComponent {...props} {...newProps} />;
  };

// helper functions to get and create display names for react components
export function getDisplayName(
  Component: React.ComponentType<any>,
  fallback: string = "Component"
): string {
  return Component.displayName || Component.name || fallback;
}

export function makeHOCDisplayName(
  hocName: string,
  ComponentToWrap: React.ComponentType<any>,
  fallback: string = "Component"
) {
  return `${hocName}(${getDisplayName(ComponentToWrap, fallback)})`;
}
