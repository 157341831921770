"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportTicketPriority = void 0;
var SupportTicketPriority;
(function (SupportTicketPriority) {
    SupportTicketPriority[SupportTicketPriority["urgent"] = 4] = "urgent";
    SupportTicketPriority[SupportTicketPriority["high"] = 3] = "high";
    SupportTicketPriority[SupportTicketPriority["normal"] = 2] = "normal";
    SupportTicketPriority[SupportTicketPriority["low"] = 1] = "low";
})(SupportTicketPriority = exports.SupportTicketPriority || (exports.SupportTicketPriority = {}));
