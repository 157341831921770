import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { resetPasswordApi } from "apis/auth";
import * as React from "react";

import { useStyles } from "./sectionStyles";
import { formatStringWithJsx, getUrlParams } from "./util";

const strings = {
  mismatch: "Passwords do not match",
  password: "New password",
  confirmPassword: "Confirm password",
  submit: "Submit",
  error: "Something went wrong",
  consent:
    "By clicking Submit, you agree to our {0} and {1}. You may receive SMS notifications from us and can opt out at any time.",
  tos: "Terms of Service",
  pp: "Privacy Policy",
  tosLink: "//memorahealth.com/terms-of-service",
  ppLink: "//memorahealth.com/legal/privacy-policy",
  consentRequired:
    "Confirm consent to Terms of Service and Privacy Policy to proceed",
};

const consentLabel = formatStringWithJsx(
  strings.consent,
  <Link href={strings.tosLink}>{strings.tos}</Link>,
  <Link href={strings.ppLink}>{strings.pp}</Link>
);

export const ActivateAccount: React.FC = () => {
  const queryParams = React.useRef(getUrlParams(window.location));
  const { token } = queryParams.current;

  const [password, setPassword] = React.useState<string>();
  const [password2, setPassword2] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [pw2Dirty, setPw2Dirty] = React.useState(false);
  const [mismatch, setMismatch] = React.useState<boolean>();
  const [consented, setConsented] = React.useState(false);

  React.useEffect(() => {
    setMismatch(password !== password2);
  }, [password, password2]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== password2) {
      setErrorMessage(strings.mismatch);
      setIsSubmitting(false);
      return;
    }

    if (!consented) {
      setErrorMessage(strings.consentRequired);
      setIsSubmitting(false);
      return;
    }

    setErrorMessage(undefined);
    setIsSubmitting(true);

    resetPasswordApi(password!, token as string)
      .then((res) => {
        setIsSubmitting(false);
        if (res.status === 200 && res.data.redirect) {
          window.location.href = res.data.redirect;
        } else {
          setErrorMessage(res?.data?.message || strings.error);
        }
        return undefined;
      })
      .catch((error) => {
        setIsSubmitting(false);
        setErrorMessage(error?.response?.data?.message || strings.error);
      });
  };

  const styles = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <Container className={styles.container}>
        <Typography component="span" className={styles.error}>
          {errorMessage}
        </Typography>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <LockIcon />
          </Grid>
          <Grid item className={styles.inputItem}>
            <TextField
              variant="standard"
              label={strings.password}
              size="small"
              type="password"
              autoComplete="off"
              className={styles.input}
              onChange={(val) => setPassword(val.target.value)}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <LockIcon />
          </Grid>
          <Grid item className={styles.inputItem}>
            <TextField
              variant="standard"
              label={strings.confirmPassword}
              size="small"
              type="password"
              autoComplete="off"
              className={styles.input}
              onChange={(val) => {
                setPw2Dirty(true);
                setPassword2(val.target.value);
              }}
              required
              error={pw2Dirty && mismatch}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              required
              onChange={(event) => setConsented(event.target.checked)}
              color="primary"
            />
          }
          label={consentLabel}
        />
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.button}
            disabled={isSubmitting}
          >
            {strings.submit}
          </Button>
          {isSubmitting && (
            <CircularProgress size={24} className={styles.buttonSpinner} />
          )}
        </div>
      </Container>
    </form>
  );
};
