export function getUrlParams(url: Location) {
  const params: Record<string, string> = {};

  const queryParams = url.search.slice(1); // Take off the leading "?"
  const paramsArray = queryParams.split("&");
  for (const element of paramsArray) {
    const parts = element.split("=", 2);
    const key = parts[0] as string;
    const value = decodeURIComponent(parts?.[1] || "");
    params[key] = value;
  }

  return params;
}

export function decodeBase64(str: string) {
  if (typeof str === "string") {
    return window.atob(str);
  }
  return undefined;
}

const tokenPattern = /({\d+})/g;

export function formatStringWithJsx(str: string, ...elements: JSX.Element[]) {
  const matches = str.match(tokenPattern);
  const parts = str.split(tokenPattern);

  if (!matches || matches.length === 0) {
    return str;
  }

  return parts.map((part) => {
    const elemIndex = Number.parseInt(part.substring(1, part.length - 1));
    const partToDisplay = matches.includes(part) ? elements[elemIndex] : part;
    return partToDisplay;
  });
}
