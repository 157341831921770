"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isChildScheduleSurveyOrEndAndSendSurvey = exports.isChildEndAndSendSurvey = exports.isChildEscalationType = exports.isChildSurveyType = void 0;
function isChildSurveyType(params) {
    return params.type === "survey";
}
exports.isChildSurveyType = isChildSurveyType;
function isChildEscalationType(params) {
    return params.type === "escalation";
}
exports.isChildEscalationType = isChildEscalationType;
function isChildEndAndSendSurvey(params) {
    return params.type === "endAndSendSurvey";
}
exports.isChildEndAndSendSurvey = isChildEndAndSendSurvey;
function isChildScheduleSurveyOrEndAndSendSurvey(params) {
    return params.type === "survey" || params.type === "endAndSendSurvey";
}
exports.isChildScheduleSurveyOrEndAndSendSurvey = isChildScheduleSurveyOrEndAndSendSurvey;
