import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  error: {
    color: theme.palette.red[60],
    minHeight: 24,
    fontSize: "0.85rem",
  },
  loginRow: {
    marginBottom: theme.spacing(3),
  },
  loginIcon: {
    marginLeft: 2,
  },
  inputItem: {
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
    },
  },
  input: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    width: "416px",
  },
  buttonWrapper: {
    marginTop: theme.spacing(9),
    position: "relative",
    marginBottom: theme.spacing(3),
  },
  button: {},
  buttonSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  progressBarContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(1),
  },
  progressBar: {
    width: "100%",
  },
  totpInput: {
    fontFamily: "monospace, monospace",
    fontSize: "2rem",
  },
}));
