"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurveyStatuses = void 0;
/* -------------------------------------------------------------------------- */
/*                             Patient Survey API types                       */
/* -------------------------------------------------------------------------- */
var SurveyStatuses;
(function (SurveyStatuses) {
    SurveyStatuses["OPEN"] = "open";
    SurveyStatuses["INCOMPLETE"] = "incomplete";
    SurveyStatuses["COMPLETE"] = "complete";
})(SurveyStatuses = exports.SurveyStatuses || (exports.SurveyStatuses = {}));
