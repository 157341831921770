"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.medicationOptions = void 0;
exports.medicationOptions = [
    {
        value: "Capecitabine (Xeloda) 150mg",
        label: "Capecitabine (Xeloda) 150mg",
    },
    {
        value: "Capecitabine (Xeloda) 500mg",
        label: "Capecitabine (Xeloda) 500mg",
    },
    {
        value: "Temozolomide (Temodar) 5mg",
        label: "Temozolomide (Temodar) 5mg",
    },
    {
        value: "Temozolomide (Temodar) 20mg",
        label: "Temozolomide (Temodar) 20mg",
    },
    {
        value: "Temozolomide (Temodar) 100mg",
        label: "Temozolomide (Temodar) 100mg",
    },
    {
        value: "Temozolomide (Temodar) 140mg",
        label: "Temozolomide (Temodar) 140mg",
    },
    {
        value: "Temozolomide (Temodar) 180mg",
        label: "Temozolomide (Temodar) 180mg",
    },
    {
        value: "Temozolomide (Temodar) 250mg",
        label: "Temozolomide (Temodar) 250mg",
    },
];
