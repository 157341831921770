/**
 * Config, usually derived from build time variables
 */

/**
 * Git revision numbers (available in deployed contexts)
 */
export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA || undefined;

/**
 * Launchdarkly details
 */
const SANDBOX_LD_CLIENT_ID = "61e7ad75c472ca168774fd01";
export const LD_CLIENT_ID =
  process.env.REACT_APP_LD_CLIENT_ID || SANDBOX_LD_CLIENT_ID;

/**
 * Sentry details
 */
export const SENTRY_DSN =
  "https://dc3952926cb54749a158fea520d82338@o1108704.ingest.sentry.io/6171268";
export const SENTRY_TUNNEL_ENDPOINT = "/sentry-tunnel";
const SENTRY_DISABLED_ENVIRONMENTS = ["dev"];
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "dev";
export const USE_SENTRY = !SENTRY_DISABLED_ENVIRONMENTS.includes(SENTRY_ENV);

/**
 * Environment designation
 */
export const ENV_DESIGNATION_NAME = process.env.REACT_APP_ENV_DESIGNATION_NAME;
export const ENV_DESIGNATION_COLOR =
  process.env.REACT_APP_ENV_DESIGNATION_COLOR;
export const ENV_DESIGNATION_MESSAGE =
  process.env.REACT_APP_ENV_DESIGNATION_MESSAGE;
export const ENV_DESIGNATION_INTERNAL = process.env
  .REACT_APP_ENV_DESIGNATION_INTERNAL
  ? process.env.REACT_APP_ENV_DESIGNATION_INTERNAL === "true"
  : false;

export const IS_PRODUCTION = SENTRY_ENV === "prod2" || SENTRY_ENV === "prod2ca";
/**
 * Changes to provider warnings
 */
export const ENV_DISABLE_PATIENT_RESOURCES_WARNING =
  !!process.env.REACT_APP_DISABLE_PATIENT_RESOURCES_WARNING;

/**
 * Allow changes to provider POMP (patients on multiple providers) settings
 */
export const ENV_ALLOW_POMP_CHANGES =
  !!process.env.REACT_APP_ALLOW_POMP_CHANGES;
