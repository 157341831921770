"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLegacyProgram = exports.isProgramTemplate = void 0;
function isProgramTemplate(program) {
    return program.isTemplate;
}
exports.isProgramTemplate = isProgramTemplate;
function isLegacyProgram(program) {
    const castedProgram = program;
    return !castedProgram.isTemplate && !castedProgram.parentTemplateId;
}
exports.isLegacyProgram = isLegacyProgram;
