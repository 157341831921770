"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverallFilterIds = void 0;
/**
 * Query param `filterId` used for filtering our main dashboard.
 */
exports.OverallFilterIds = {
    ACTIVE_IDS: "allActiveIds",
    ALL_IDS: "allIds",
    UNANSWERED_IDS: "unansweredIds",
    APPT_REFILL_IDS: "apptRefillIds",
    INACTIVE_IDS: "allInactiveIds",
    ARCHIVED_IDS: "archivedIds",
    OPTED_OUT_IDS: "optedOutIds",
    CONSENTED_IDS: "consentedIds",
    UNCONSENTED_IDS: "unconsentedIds",
    INACTIVE_CONSENTED_IDS: "inActiveConsentedIds",
    INACTIVE_UNCONSENTED_IDS: "inActiveUnconsentedIds",
    UNVERIFIED_IDS: "unverifiedIds",
};
