/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import { loginApi } from "apis/auth";
import * as React from "react";

import { useStyles } from "./sectionStyles";

const strings = {
  email: "Email Address",
  password: "Password",
  error: "Something went wrong",
  submit: "Sign in",
  forgotPassword: "Forgot password",
};

/** String returned by backend when login succeeded but TOTP is required. */
const PROMPT_TOTP = "TOTP_REQUIRED";

interface ILoginProps {
  redirect: string | undefined;
  initialMessage: string | undefined;

  onChangeUsername: (username: string) => void;
  onChangePassword: (password: string) => void;
  /** Callback to switch to Forgot Password mode. */
  onForgotPassword: () => void;
  /** Callback to switch to TOTP mode. */
  onTotp: () => void;
}

export const Login = ({
  redirect,
  initialMessage,
  onChangeUsername,
  onChangePassword,
  onTotp,
  onForgotPassword,
}: ILoginProps) => {
  const [username, setUsername] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    initialMessage
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage(undefined);
    setIsSubmitting(true);
    loginApi(username!, password!, redirect, undefined)
      .then((res) => {
        setIsSubmitting(false);
        if (res.status === 200 && res.data.redirect) {
          window.location.href = res.data.redirect;
        } else {
          if (res?.data?.message === PROMPT_TOTP) {
            onChangeUsername(username!);
            onChangePassword(password!);
            onTotp();
            return undefined;
          }
          setErrorMessage(res?.data?.message || strings.error);
        }
        return undefined;
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error?.response?.data?.message === PROMPT_TOTP) {
          onChangeUsername(username!);
          onChangePassword(password!);
          onTotp();
          return;
        }
        setErrorMessage(error?.response?.data?.message || strings.error);
      });
  };

  const styles = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.container}>
        <Typography component="span" className={styles.error}>
          {errorMessage}
        </Typography>

        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          className={styles.loginRow}
        >
          <Grid item className={styles.loginIcon}>
            <AccountCircleIcon />
          </Grid>
          <Grid item className={styles.inputItem}>
            <TextField
              variant="standard"
              label={strings.email}
              type="email"
              size="small"
              autoComplete="chrome-off"
              className={styles.input}
              onChange={(val) => setUsername(val.target.value)}
              required
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          className={styles.loginRow}
        >
          <Grid item className={styles.loginIcon}>
            <LockIcon />
          </Grid>
          <Grid item className={styles.inputItem}>
            <TextField
              variant="standard"
              label={strings.password}
              size="small"
              autoComplete="chrome-off"
              type="password"
              className={styles.input}
              onChange={(val) => setPassword(val.target.value)}
              required
            />
          </Grid>
        </Grid>

        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.button}
            disabled={isSubmitting}
          >
            {strings.submit}
          </Button>
          {isSubmitting && (
            <CircularProgress size={24} className={styles.buttonSpinner} />
          )}
        </div>
        <Link component="button" onClick={onForgotPassword}>
          {strings.forgotPassword}
        </Link>
      </div>
    </form>
  );
};
