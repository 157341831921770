import type { IUserState, PanelResponse } from "@pillpal/api-types";
import axios from "axios";
import type { UpdatePasswordType } from "containers/updatepassword/components/UpdatePasswordForm/types";

import { axiosClient } from "../helpers/axiosClient";

export const resetPasswordApi = (password: string, token: string) =>
  axios.post<{ message: string; redirect: string }>("/api/reset-password", {
    newpw1: password,
    token,
  });

export const requestPasswordResetApi = (username: string) =>
  axios.post<{ message: string }>("/api/reset-password-email", {
    email: username,
  });

export const resendAccountActivationApi = (username: string) =>
  axios.post<{ message: string }>("/api/resend-account-activation", {
    email: username,
  });

export const changePasswordAPI = async (
  data: UpdatePasswordType
): Promise<void> => {
  await axiosClient.post("/api/change-password", data);
};

export const loginApi = (
  username: string,
  password: string,
  redirect?: string,
  code?: string
) =>
  axios.post<{ message: string; redirect: string }>("/api/login", {
    username,
    password,
    code,
    redirect,
  });

export async function authenticationAPI(): Promise<PanelResponse> {
  const response = await axiosClient.get("/api/session/panel");
  return response.data;
}

export const logoutAPI = async () => {
  const response = await axiosClient.get("/api/logout");
  return response.data;
};

export const currentUserAPI = async (): Promise<IUserState> => {
  const response = await axiosClient.get<IUserState>("/api/session");
  return response.data;
};
