import type { AxiosError } from "axios";
import axios from "axios";
import { apiBaseUrl } from "components/tests/nockSetup/envDomainMapper";

import { AuthMissingError, FetchError } from "../infra/errors";
import { clientLogger } from "../logging/logger";
import { Observable } from "./observable";

const AuthMissingChannel = new Observable<number>();

export interface AxiosErrorWithMessage extends AxiosError<any> {
  errorMessage?: string;
  errorStatusCode?: number;
  errorsByKey?: object;
}

const ERROR_MESSAGE_KEYS = [
  "msg",
  "message",
  "status",
  "err",
  "errors",
  "error",
];

export const addOnAuthMissingHandler =
  AuthMissingChannel.subscribe.bind(AuthMissingChannel);
export const removeOnAuthMissingHandler =
  AuthMissingChannel.unsubscribe.bind(AuthMissingChannel);

const reISO =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}\.\d*)(?:Z|([+-])([\d:|]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[/\\|]$/;

// browsers like IE11 will aggressively cache GET requests, leading to stale data
axios.defaults.headers.get.Pragma = "no-cache";
axios.defaults.headers.get["Cache-Control"] = "no-store";
axios.defaults.adapter = require("axios/lib/adapters/http");

/** Singleton axios request client for use throughout the app. Add common headers / etc here. */
export const axiosClient = axios.create({
  baseURL: apiBaseUrl,
});
// Add a response interceptor
axiosClient.interceptors.response.use((response) => response, errorHandler);

// passed into the interceptor which standardizes errors we receive from the backend by extending the AxiosError type to an
// AxiosErrorWithMessage type, bringing error messages and status codes to the top level of the object.
// Downstream users can use errorMessage to display the originally nested error message
export function errorHandler(error: AxiosError): AxiosErrorWithMessage {
  const modifiedError = error as AxiosErrorWithMessage;
  if (modifiedError.response?.status) {
    if (
      modifiedError.response.status === 403 ||
      modifiedError.response.status === 401
    ) {
      AuthMissingChannel.notify(modifiedError.response.status);
      throw new AuthMissingError();
    }

    modifiedError.errorStatusCode = modifiedError.response?.status;
    let message: string | object | null = safelyGetKeyWithFallbacks(
      modifiedError.response.data
    );

    if (message) {
      if (typeof message === "object") {
        modifiedError.errorsByKey = message;

        // set upper level message to first error message
        const [errorMessage] = Object.values(message);
        message = errorMessage as string;
        modifiedError.message = message;
        modifiedError.errorMessage = message;
      } else if (typeof message === "string") {
        modifiedError.errorMessage = message || modifiedError.message;
      }
    } else if (
      modifiedError.response.data &&
      typeof modifiedError.response.data === "string"
    ) {
      modifiedError.errorMessage =
        modifiedError.response.data || modifiedError.message;
    }
    throw modifiedError;
  }

  throw modifiedError;
}

export const axiosClientWithDateParsing = axios.create({
  baseURL: apiBaseUrl,
});
axiosClientWithDateParsing.interceptors.response.use((response) => {
  try {
    response.data = translateDates(response.data);
    return response;
  } catch (err) {
    clientLogger.warn("Error parsing data", { err });
    return response;
  }
}, errorHandler);

function translateDates(value: any): any {
  if (typeof value === "object" && !!value) {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        // eslint-disable-next-line no-param-reassign
        value[i] = translateDates(value[i]);
      }
    }
    for (const property of Object.keys(value)) {
      // eslint-disable-next-line no-param-reassign
      value[property] = translateDates(value[property]);
    }
  }
  if (typeof value === "string") {
    let a = reISO.exec(value);
    if (a) return new Date(value);
    a = reMsAjax.exec(value);
    if (a) {
      const b = a[1]!.split(/[+,.-]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]!);
    }
  }

  return value;
}

// exported for testing
export async function createErrorFromResponse(response: Response) {
  const body = await safelyGetBody(response);
  if (body) {
    const jsonError = createErrorFromJSON(body, response);
    if (jsonError) {
      return jsonError;
    }
    return new FetchError(body, response);
  }
  return new FetchError("Fetch error", response);
}

async function safelyGetBody(response: Response) {
  try {
    const body = await response.text();
    return body;
  } catch (e) {
    return null;
  }
}

function createErrorFromJSON(text: string, response: Response) {
  try {
    const errorObject = JSON.parse(text);
    let message = safelyGetKeyWithFallbacks(errorObject);
    if (message) {
      if (typeof message === "object") {
        const [errorMessage] = Object.values(message);
        message = errorMessage as string;
      }
      return new FetchError(message, response);
    }
    return null;
  } catch (e) {
    return null;
  }
}

function safelyGetKeyWithFallbacks(
  obj: Record<string, string> | undefined
): string | null {
  if (obj === undefined) {
    return null;
  }
  for (const key of ERROR_MESSAGE_KEYS) {
    const val = obj[key];
    if (val) {
      return val;
    }
  }
  return null;
}
