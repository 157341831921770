import "fonts/suisse/suisse.css";

import type { PaletteColorOptions } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import createSpacing from "@material-ui/core/styles/createSpacing";
import type {} from "@material-ui/lab/themeAugmentation";
import type { CSSProperties } from "@material-ui/styles";

import type { MHDSTypography } from "./mhdsTypography";
import { MHDSVariantStyles } from "./mhdsTypography";
import * as memoraColors from "./palette";

// Setup the additional memoraGrey style
declare module "@material-ui/core/styles/createPalette" {
  interface Palette extends Omit<memoraColors.Palette, "grey"> {
    memoraGrey: memoraColors.Palette["grey"];
  }
  interface PaletteOptions extends Omit<memoraColors.Palette, "grey"> {
    memoraGrey: memoraColors.Palette["grey"];
  }
}

// Extend the Typography interface, adding our custom variants & support autocomplete with typescript
declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    displayEmphasis: CSSProperties;
    displaySubtle: CSSProperties;
    titleLarge: CSSProperties;
    titleSmall: CSSProperties;
    emphasisLarge: CSSProperties;
    emphasisMedium: CSSProperties;
    emphasisSmall: CSSProperties;
    standardLarge: CSSProperties;
    standardMedium: CSSProperties;
    standardSmall: CSSProperties;
    labelLarge: CSSProperties;
    labelMedium: CSSProperties;
    labelSmall: CSSProperties;
    linkLarge: CSSProperties;
    linkMedium: CSSProperties;
    linkSmall: CSSProperties;
  }
}

// This sets up the default spacing to work in increments of 4,
// so `theme.spacing(2)` will output `8px`
const spacing = createSpacing(4);
const htmlFontSize = 16;

// Create the typography object that we pass into the theme
// - with the proper mapping for existing mui variant names
export const typography: MHDSTypography = {
  fontFamily: [
    "SuisseScreen",
    "San Francisco",
    "Roboto",
    "Segoe UI",
    "Arial",
    "sans-serif",
  ].join(","),
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  fontWeightLight: 300,
  fontSize: htmlFontSize,
  pxToRem: (size: number) => `${size / htmlFontSize}rem`,

  // New SuisseScreen based typography
  ...MHDSVariantStyles,

  // Exisiting MUI names -> mapped to new mhds style names
  h1: MHDSVariantStyles.displayEmphasis,
  h2: MHDSVariantStyles.titleLarge,
  h3: MHDSVariantStyles.titleSmall,
  h4: MHDSVariantStyles.emphasisLarge,
  h5: MHDSVariantStyles.emphasisMedium,
  h6: MHDSVariantStyles.emphasisSmall,
  body1: MHDSVariantStyles.standardLarge,
  body2: MHDSVariantStyles.standardMedium,
  subtitle1: MHDSVariantStyles.labelLarge,
  subtitle2: MHDSVariantStyles.labelMedium,
  button: {
    ...MHDSVariantStyles.emphasisLarge,
    textTransform: "initial",
  },
  caption: MHDSVariantStyles.labelMedium,
  overline: {
    ...MHDSVariantStyles.labelMedium,
    textTransform: "initial",
  },
};

export type ColorPalette = {
  [index: number]: string;
};

function memoraToMuiColor<T extends Record<number, string>>(
  memoraColor: T,
  mapping: Extract<keyof T, number>[]
): PaletteColorOptions {
  return {
    light: memoraColor[mapping[0] as number] as string,
    main: memoraColor[mapping[1] as number] as string,
    dark: memoraColor[mapping[2] as number] as string,
  };
}

// so as not to blow away MUI grey
const { grey: memoraGrey, ...restOfPalette } = memoraColors;

export const theme = createTheme({
  spacing: 4,
  typography,
  palette: {
    type: "light",
    primary: memoraToMuiColor(memoraColors.blue, [10, 41, 55]),
    secondary: memoraToMuiColor(memoraColors.navy, [10, 68, 78]),
    error: memoraToMuiColor(memoraColors.red, [13, 50, 60]),
    warning: memoraToMuiColor(memoraColors.orange, [10, 20, 50]),
    info: memoraToMuiColor(memoraColors.navy, [10, 68, 78]),
    success: memoraToMuiColor(memoraColors.green, [10, 41, 60]),
    text: {
      primary: memoraColors.grey[100],
      secondary: memoraColors.grey[55],
      disabled: memoraColors.grey[28],
      hint: memoraColors.grey[28],
    },
    background: {
      paper: memoraColors.grey[0],
      default: memoraColors.grey[12],
    },
    action: {
      selected: memoraColors.blue[41],
    },
    ...restOfPalette,
    memoraGrey,
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h6",
        subtitle1: "span",
        subtitle2: "span",
        body1: "span",
        body2: "span",
      },
    },
    MuiCard: {
      elevation: 0,
      variant: "outlined",
    },
    MuiTabs: {
      indicatorColor: "primary",
    },
    MuiSwitch: {
      color: "primary",
    },
    MuiLink: {
      color: "textPrimary",
      underline: "none",
    },
    MuiListItemText: {
      primaryTypographyProps: {
        variant: "button",
      },
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiAppBar: {
      variant: "outlined",
    },
    MuiRadio: {
      style: {
        margin: "0 6px 0 0",
        padding: 0,
        border: 0,
      },
    },
    MuiDialogContentText: {
      color: "textPrimary",
    },
  },
  overrides: {
    MuiScopedCssBaseline: {
      root: {
        // we want bigger font as default
        ...typography.body1,
      },
    },
    MuiRadio: {
      colorPrimary: {
        background: "none",
        "&:hover, &.Mui-focusVisible": {
          background: "none",
          color: memoraColors.blue[41],
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: memoraColors.blue[50],
      },
    },
    MuiAutocomplete: {
      input: {
        ...typography.labelMedium,
      },
      option: {
        ...typography.labelMedium,
      },
      noOptions: {
        textAlign: "center",
        ...typography.labelMedium,
      },
    },
    MuiChip: {
      root: {
        ...typography.labelSmall,
      },
      deleteIconSmall: {
        width: 12,
        height: 12,
        color: memoraColors.grey[100],
        marginRight: "4px!important",
      },
      deleteIcon: {
        width: 14,
        height: 14,
        color: memoraColors.grey[100],
        marginRight: "6px!important",
      },
    },
    MuiAlert: {
      standardError: {
        color: memoraColors.red[60],
        background: memoraColors.red[13],
      },
      standardInfo: {
        color: memoraColors.navy[78],
        background: memoraColors.navy[10],
      },
      standardSuccess: {
        color: memoraColors.green[60],
        background: memoraColors.green[10],
      },
      standardWarning: {
        color: memoraColors.orange[50],
        background: memoraColors.orange[10],
      },
    },
    MuiTooltip: {
      tooltip: {
        ...typography.labelSmall,
      },
    },
    MuiLink: {
      root: {
        // TODO: remove when unfortunate global setting is gone
        "&:hover, &:focus": {
          color: memoraColors.blue[41],
          textDecoration: "none",
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: "0 !important", // need important here to override viewport specific things
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: spacing(1),
      },
      adornedEnd: {
        paddingRight: spacing(1),
      },
    },
    MuiTablePagination: {
      caption: {
        ...typography.body1,
      },
    },
    MuiListItem: {
      root: {
        "&$focusVisible": {
          color: memoraColors.grey[0],
        },
        "&$selected, &$selected:hover": {
          backgroundColor: memoraColors.blue[41],
          color: memoraColors.grey[0],
        },
      },
      button: {
        "&:hover": {
          color: "inherit", // TODO: can remove when we remove global a style
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        minWidth: 0,
        marginLeft: 4,
        marginRight: 12,
      },
    },
    MuiTableCell: {
      root: {
        ...typography.standardMedium,
        padding: spacing(2, 3),
      },
      head: {
        ...typography.emphasisMedium,
        color: memoraColors.grey[55],
        padding: spacing(1, 3),
      },
    },
    MuiAppBar: {
      root: {
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
      },
      colorDefault: {
        color: memoraColors.grey[100],
        backgroundColor: memoraColors.grey[0],
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: spacing(4, 6),
      },
      spacing: {
        "& > :not(:first-child)": {
          marginLeft: spacing(4),
        },
      },
    },
  },
});
